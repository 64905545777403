import r2wc from "@r2wc/react-to-web-component";
import { FC } from "react";
import { Button } from "react-aria-components";
import styles from "./auth.module.css";
import { useUserInfo } from "./hooks";

export type AuthProps = {
  userInfoUrl: string;
};

const Auth: FC<AuthProps> = ({ userInfoUrl }) => {
  const { data: info, isLoading } = useUserInfo(userInfoUrl);

  if (isLoading) return null;

  if (info) {
    const name = info.name ?? info.email;
    return (
      <div>
        Hi {name}. <Button className={styles.button}>Sign Out2</Button>
      </div>
    );
  }

  return <div>Sign In</div>;
};

export const WebAuth = r2wc<AuthProps>(Auth, {
  props: {
    userInfoUrl: "string",
  },
});
