import r2wc from "@r2wc/react-to-web-component";
import { FC } from "react";
import { useAuthorizedSites } from "./hooks";

export type LinksProps = {
  authorizedSitesUrl: string;
};

const DocsLinks: FC<LinksProps> = ({ authorizedSitesUrl }) => {
  const { data: links, isLoading } = useAuthorizedSites(authorizedSitesUrl);

  if (isLoading) return null;

  return (
    <ul>
      {links?.sites.map(({ name, path }) => (
        <li>
          <a href={path}>{name}</a>
        </li>
      ))}
    </ul>
  );
};

export const WebDocsLinks = r2wc<LinksProps>(DocsLinks, {
  props: {
    authorizedSitesUrl: "string",
  },
});
