import { QueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

const apiClient = axios.create({
  timeout: 3000,
  validateStatus: (status) => [200, 401].includes(status),
});

apiClient.interceptors.response.use((res) => {
  // Handle unauthorized as null data.
  if (res.status === 401) res.data = null;
  return res;
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
    },
  },
});

const UserInfo = z
  .object({
    name: z.string().nullish(),
    email: z.string().nullish(),
  })
  .nullable();

export const useUserInfo = (userInfoUrl: string) => {
  return useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: async () => {
        const res = await apiClient.get(userInfoUrl);
        return UserInfo.parse(res.data);
      },
    },
    queryClient
  );
};

const AuthorizedSites = z
  .object({
    sites: z.array(
      z.object({
        name: z.string(),
        path: z.string(),
      })
    ),
  })
  .nullable();

export const useAuthorizedSites = (authorizedSitesUrl: string) => {
  return useQuery(
    {
      queryKey: ["authorizedSites"],
      queryFn: async () => {
        const res = await apiClient.get(authorizedSitesUrl);
        return AuthorizedSites.parse(res.data);
      },
    },
    queryClient
  );
};
